<template>
    <div class="my-3">
        <div class="text-center">
            <h1 class="h3">
                Banners encontrados
                <strong class="text-primary">
                    {{ itemsNumber }}
                </strong>
            </h1>
        </div>

        <div class="d-flex flex-wrap mt-2">
            <div class="col-12 my-4">
                <banners-filters v-model="query" @enter="reloadItems" />
            </div>

            <div
                class="d-flex justify-content-between flex-column flex-lg-row col-12"
            >
                <div
                    class="my-3 d-flex align-items-center justify-content-around"
                >
                    <vs-button v-show="query" relief @click="reloadItems">
                        Consultar 🔎
                    </vs-button>

                    <div class="d-flex">
                        <tooltip-button
                            v-show="
                                selected.length &&
                                $ability.hasScope('banner:Create')
                            "
                            icon="©"
                            message="Clonar banner"
                            @click="cloneSelected"
                        />
                    </div>
                </div>

                <div
                    class="my-3 d-flex align-items-center justify-content-around"
                >
                    <vs-tooltip border>
                        <vs-button
                            v-show="selected.length"
                            circle
                            icon
                            floating
                            @click="clearSelected"
                        >
                            🧹
                        </vs-button>

                        <template #tooltip> Limpiar selección </template>
                    </vs-tooltip>

                    <vs-pagination
                        v-model="currentPage"
                        :length="pagesNumber"
                        @input="listItems()"
                    />

                    <tooltip-redirect
                        v-if="$ability.hasScope('banner:Create')"
                        to="/app/banners/new"
                        message="Nuevo banner"
                    >
                    </tooltip-redirect>
                </div>
            </div>
        </div>

        <div v-show="showTable" class="w-100 mt-2">
            <b-table
                ref="selectableTable"
                :fields="$data.$fields"
                :items="itemsCurrent"
                :dark="activeDark"
                :per-page="itemsCurrent.length"
                select-mode="single"
                responsive
                selectable
                outlined
                no-border-collapse
                hover
                class="col-12"
                @row-selected="onRowSelected"
            >
                <template #cell(id)="data">
                    <router-link
                        :to="`/app/banners/detail/${data.item.id}`"
                        class="text-center font-weight-normal btn btn-primary p-1"
                    >
                        <small>
                            {{ data.item.active ? "🟢" : "⚪" }}
                            &nbsp; {{ data.item.id }}
                        </small>
                    </router-link>
                </template>

                <template #cell(name)="data">
                    <small class="text-center font-weight-normal">
                        {{ data.item.name | cut(36) }}
                    </small>
                </template>

                <template #cell(line)="data">
                    <a
                        v-if="data.item.line"
                        :href="`/app/lines/detail/${data.item.line.slug}`"
                        target="_blank"
                        rel="noopener"
                        class="d-flex flex-column align-items-center"
                    >
                        <small class="text-center">
                            {{ data.item.line.name }}
                        </small>
                    </a>
                    <span> Root </span>
                </template>
                <template #cell(order)="data">
                    <small class="text-center font-weight-normal">
                        {{ data.item.order }}
                    </small>
                </template>
                <template #cell(updatedAt)="data">
                    <small class="text-center font-weight-normal">
                        {{ data.item.updatedAt | date }}
                    </small>
                </template>
            </b-table>
            <div v-if="!itemsCurrent.length" class="col-11 mx-auto mt-4">
                <strong>
                    Nuestro sistema NO encuentra resultados. Intenta modificar
                    los filtros de búsqueda.
                </strong>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";

import BannersFilters from "@/components/banners/BannersFilters.vue";
import TooltipButton from "@/components/utils/TooltipButton.vue";
import TooltipRedirect from "@/components/utils/TooltipRedirect.vue";

export default {
    name: "ProductsIndex",
    components: { BannersFilters, TooltipRedirect, TooltipButton },
    layout: "app",
    data: () => ({
        loaderInstance: null,
        query: {},
        itemsCurrent: [],
        $fields: [
            { key: "id", label: "id", sortable: false },
            { key: "name", label: "Nombre", sortable: true },
            { key: "line", label: "Línea", sortable: true },
            { key: "order", label: "Orden", sortable: true },
            { key: "updatedAt", label: "📆 Actualización", sortable: true }
        ],
        showTable: false,
        currentPage: 1,
        pagesNumber: 0,
        itemsNumber: 0,
        selected: []
    }),
    computed: {
        ...mapState("control", ["activeDark"]),
        ...mapGetters("control", ["backgroundColor"])
    },
    watch: {},
    async mounted() {
        const verifyPageScope = this.$ability.verifyPageScope.bind(this);
        await verifyPageScope("banner:List", "/app");
        this.listItems(true);
    },
    methods: {
        ...mapActions("banners", ["listBanners"]),
        async listItems(required) {
            this.isLoading = true;
            this.loaderInstance = this.$vs.loading({ type: "circles" });
            try {
                const { items, pagination } = await this.listBanners({
                    required,
                    page: this.currentPage,
                    ...this.query
                });
                this.itemsCurrent = items;
                if (pagination) {
                    this.itemsNumber = pagination.itemsNumber;
                    this.pagesNumber = pagination.pagesNumber;
                }
                this.showTable = true;
            } catch (error) {
                this.loaderInstance.close();
                this.$swal.fire({
                    background: this.backgroundColor,
                    title: error.title,
                    text: error.message,
                    icon: error.icon
                });
            } finally {
                this.loaderInstance.close();
            }
        },
        async reloadItems() {
            this.currentPage = 1;
            await this.listItems(true);
        },

        onRowSelected(items) {
            this.selected = items;
        },
        cloneSelected() {
            window
                .open(`/app/banners/clone/${this.selected[0].id}`, "_blank")
                .focus();
        },
        clearSelected() {
            this.$refs.selectableTable.clearSelected();
            this.selected = [];
        }
    }
};
</script>
