<template>
    <div>
        <div class="d-flex justify-content-center w-100">
            <vs-tooltip bottom border>
                <vs-button icon circle @click="openNewFilter">
                    <img
                        src="@/assets/images/filter.svg"
                        height="15"
                        width="15"
                        alt="reload img"
                    />
                </vs-button>

                <template #tooltip> Modificar filtros de búsqueda </template>
            </vs-tooltip>
        </div>

        <div class="d-flex flex-column mt-1">
            <div
                v-if="activeFilters.includes('line')"
                class="col-md-8 col-12 mx-auto mb-4"
            >
                <subline-selector
                    v-model="line"
                    add-root
                    :default-filters="{}"
                    enabled
                    @change="applyFilter"
                />
            </div>

            <div
                v-if="activeFilters.includes('keyword')"
                class="col-md-8 col-12 mx-auto mb-4"
            >
                <vs-input
                    v-model="keyword"
                    border
                    shadow
                    type="text"
                    placeholder="Buscar por palabra clave .."
                    class="mr-2 opacity-false"
                    @blur="applyFilter"
                    @keyup.enter="onEnter"
                >
                    <template #icon> 🔎 </template>
                </vs-input>
            </div>
        </div>

        <vs-dialog
            v-model="isOpenModal"
            scroll
            overflow-hidden
            auto-width
            @close="pushNewFilter"
        >
            <template #header>
                <div class="d-flex flex-column">
                    <div
                        class="mx-4 mt-2 d-flex flex-column flex-lg-row align-items-center"
                    >
                        <h3 class="text-center">Seleccione los filtros</h3>

                        <div>
                            <vs-button
                                :disabled="!filters.length"
                                icon
                                circle
                                @click="pushNewFilter"
                            >
                                ✔️
                            </vs-button>
                        </div>
                    </div>
                </div>
            </template>
            <div class="con-content">
                <cards-selector v-model="filters" :base="baseFilters" />
            </div>
        </vs-dialog>
    </div>
</template>

<script>
import { bannersFilters } from "@/common/fields/filters-query";

import CardsSelector from "@/components/utils/CardsSelector.vue";

export default {
    name: "BannersFilters",
    components: {
        CardsSelector,
        SublineSelector: () => import("@/components/lines/SublineSelector.vue")
    },
    model: {
        prop: "itemsCurrent",
        event: "change"
    },
    props: {
        itemsCurrent: {
            default: () => ({}),
            type: Object,
            require: true
        },
        enabledFilters: {
            default: () => ["line", "keyword"],
            type: Array,
            require: true
        }
    },
    emits: ["change", "enter"],
    data: () => ({
        activeFilters: [],
        isOpenModal: false,
        keyword: "",
        line: { slug: "otros", name: "Otros" },
        filters: [],
        baseFilters: bannersFilters
    }),
    watch: {
        enabledFilters(value) {
            this.setEnabledFilters(value);
        }
    },
    mounted() {
        this.setEnabledFilters(this.enabledFilters);
        this.activeFilters = Object.keys(this.itemsCurrent);
    },
    methods: {
        openNewFilter() {
            this.isOpenModal = true;
        },
        pushNewFilter() {
            this.activeFilters = [...this.filters];
            this.isOpenModal = false;
            this.applyFilter();
        },
        applyFilter() {
            this.$emit("change", {
                ...{
                    ...(this.activeFilters.includes("line")
                        ? { lineSlug: this.line.slug }
                        : null)
                },
                ...{
                    ...(this.activeFilters.includes("keyword")
                        ? { keyword: this.keyword }
                        : null)
                }
            });
        },
        onEnter() {
            this.applyFilter();
            this.$emit("enter");
        },
        setEnabledFilters(value) {
            this.baseFilters = bannersFilters.filter((item) =>
                value.includes(item.id)
            );
        }
    }
};
</script>
